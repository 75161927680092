import React from 'react'
import Layout from '../components/Layout'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'

const Success = ({ pageContext: { locale } }) => (
  <Layout locale={locale}>
    <div className="Hero">
      <div className="HeroGroup">
        <h1>
          <FormattedMessage id="Kyma" />
        </h1>
      </div>
    </div>
    <div className="intro">
      <h1>
        <FormattedMessage id="successmessage" />
      </h1>
      <p className="IntroServices">
        <FormattedMessage id="contactusformore" />
      </p>
    </div>
  </Layout>
)
Success.propTypes = {
  pageContext: PropTypes.shape({
    locale: PropTypes.string.isRequired
  }).isRequired
}

export default Success
